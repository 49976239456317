import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { catchError, tap } from 'rxjs';
import {
  ApiService,
  ConfigService,
  PlatformService,
  WebSocketService,
} from '../service';
import { Order, OrderStatus } from '../shared/model/order.model';
import { WaybillMergeType } from '../shared/model/waybill.model';
import { excludedOperatorsForPostingCode } from "../shared";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
  orderNumber: string;
  currentOrderStatus: OrderStatus | undefined = undefined;
  savedOrder!: Order;
  frontUrl!: string;
  showReturnForm = false;
  shopUrl!: string;
  shopName!: string;

  paymentRetriesCount: number = 0;
  isPaymentRetryProcessing = false;

  packagingHintPath = 'aktualnosci/instrukcja-pakowania-przesylek';

  private DOC_MERGE_TYPE = WaybillMergeType.FOUR_PER_A4_PAGE;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private configService: ConfigService,
    private platformService: PlatformService,
    private websocketService: WebSocketService
  ) {
    this.orderNumber = this.route.snapshot.params['orderNumber'];
    this.configService.config$.subscribe((res) => {
      this.frontUrl = res.frontUrl;
      this.showReturnForm = res.setup.sectionsConfiguration.returnForm;
      this.shopUrl = res.branding.shopUrl;
      this.shopName = res.branding.name;
    });
  }

  ngOnInit(): void {
    if (!this.orderNumber) {
      this.router.navigate(['/notfound']);
    }

    this.websocketService.orderStatuses$
      .pipe(
        catchError((error) => {
          throw error;
        }),
        tap((data) => {
          this.savedOrder = data as Order;
          this.currentOrderStatus = this.savedOrder.status;
        })
      )
      .subscribe();
    this.websocketService.connect();
    this.websocketService.sendMessage({ orderNumber: this.orderNumber });
  }

  ngOnDestroy(): void {
    this.websocketService.close();
  }

  retryPayment() {
    if (this.isPaymentRetryProcessing) {
      return;
    }

    this.isPaymentRetryProcessing = true;
    this.apiService
      .reloadPaymentUrl$(this.orderNumber)
      .subscribe({
        next: url => {
          if (this.platformService.isBrowser()) {
            window.location.href = url;
          }
        },
        error: err => {
          this.paymentRetriesCount++;
          this.isPaymentRetryProcessing = false;
        }
      });
  }

  getDocuments() {
    return this.apiService
      .getWaybill$(this.orderNumber, this.DOC_MERGE_TYPE)
      .subscribe((blob) => {
        saveAs(blob, `waybill_${this.orderNumber}.pdf`);
      });
  }

  getProtocol() {
    return this.apiService.getProtocol$(this.orderNumber).subscribe((url) => {
      window.open(url, '_blank');
    });
  }

  isOrderPostingPointBound(): boolean {
    return this.savedOrder.operatorName === 'POCZTA';
  }

  isOperatorExcludedForPostingCode(): boolean {
    return excludedOperatorsForPostingCode.includes(this.savedOrder.operatorName);
  }

  isProcessingOrder(): boolean {
    return (
      this.currentOrderStatus == OrderStatus.ADVISING ||
      this.currentOrderStatus == OrderStatus.PAYMENT_CONFIRMED ||
      this.currentOrderStatus == OrderStatus.WAITING_FOR_PAYMENT
    );
  }

  isOrderReady(): boolean {
    return this.currentOrderStatus == OrderStatus.READY_TO_SEND;
  }

  isPaymentError(): boolean {
    return this.currentOrderStatus == OrderStatus.PAYMENT_REJECTED;
  }

  isAdvisingError(): boolean {
    return this.currentOrderStatus == OrderStatus.ERROR;
  }

  isLabelless(): boolean {
    return this.savedOrder.additionalServices.some(
      (additionalService) => additionalService.name === 'LABELLESS'
    );
  }
}
