import { Injectable } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CustomValidators } from '../forms/validator';
import { OperatorSelection } from '../shared/model/operator-selection.model';
import { DeliveryType, OrderAdditionalService, ValidationAdditionalService } from '../shared/model/price.model';

import { Order, ProductItem } from '../shared/model/order.model';
import { ProductItemValues } from '../forms/model/form-controls.model';
import { TranslateService } from '@ngx-translate/core';
import { FormFieldName } from "../shared";

@Injectable({
  providedIn: 'root'
})
export class ReturnFormService {

  form!: FormGroup;
  _form$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  form$: Observable<any> = this._form$.asObservable().pipe();

  defaultLocationFormValidators: { [key: string]: ValidatorFn[] };

  operatorSelection: OperatorSelection = {};
  _operatorSelection$: BehaviorSubject<OperatorSelection> = new BehaviorSubject<any>(null);
  operatorSelection$: Observable<OperatorSelection> = this._operatorSelection$.asObservable().pipe();

  private storedPoints: any = {};
  private validatorBasedOnFormType!: Validators;
  private excludedOperatorsForPostingCode!: string[];

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService
  ) {
    this.defaultLocationFormValidators = this.getDefaultLocationFormValidators();
  }

  setOperatorSelect(selection: OperatorSelection) {
    this.operatorSelection = selection;
    this.storedPoints[selection.operator+'-'+selection.deliveryType] = selection.posId;
    this._operatorSelection$.next(this.operatorSelection);
  }

  loadForm$(showReturnForm: boolean, disableReceiverFields: boolean, excludedOperatorsForPostingCode: string[]): Observable<any> {
    this.validatorBasedOnFormType = showReturnForm ? Validators.required : Validators.nullValidator
    this.excludedOperatorsForPostingCode = excludedOperatorsForPostingCode;
    this.form = new FormGroup({
      product: this.createReturnedProductForm(),
      parcel: this.createReturnParcelForm(),
      sender: this.createLocationForm(true, false),
      seller: this.createLocationForm(false, disableReceiverFields),
      terms: new FormControl(null, [ Validators.requiredTrue ] as ValidatorFn[]),
      labelless: new FormControl(false),
      trackingReturnUrl: new FormControl(),
    })
    this._form$.next(this.form);
    return of(this.form).pipe();
  }

  markFormAsTouched(form: FormGroup) {
    form.markAllAsTouched();
    this._form$.next(form);
  }

  // needed or formArrayName to work
  get productItems(): FormArray{
    return <FormArray> this.form.get('product.productItems');
  }

  public getStoredPoint(operator: string, delivery: DeliveryType) : string|undefined {
    return this.storedPoints[operator+'-'+delivery] || undefined;
  }

  public addProductItem(): number {
    const currentItems = this.productItems.length;
    this.productItems.push(this.createProductItemForm(currentItems));
    return currentItems;
  }

  public deleteProductItem(id: number): void {
    const index = this.productItems.controls.findIndex(idx => {
      return idx.value.itemId == id
    });
    if (index !== -1) this.productItems.removeAt(index);
  }

  public get orderAdditionalServices(): OrderAdditionalService[] {
    const additionalServices: OrderAdditionalService[] = [];
    if (this.form.controls['labelless'].value) {
      additionalServices.push({value: null, name: 'LABELLESS'})
    }
    return additionalServices;
  }

  public get validationAdditionalServices(): ValidationAdditionalService[] {
    const additionalServices: ValidationAdditionalService[] = [];
    if (this.form.controls['labelless'].value) {
      additionalServices.push({value: null, type: 'LABELLESS'})
    }
    return additionalServices;
  }


  public remapFormToModel(showReturnForm: boolean): Order {
    const formValues = this.form.getRawValue();

    return {
      returnConfiguration: showReturnForm ? {
        returnShopOrderNumber: formValues.product.shopOrderNumber,
        returnReason: this.translateService.instant('RETURN_REASONS.'+formValues.product.returnReason),
        returnReasonAdditionalInfo: formValues.product.returnReasonAdditionalInfo,
        returnItems: this.mapReturnItemFormToModel(formValues.product.productItems),
      } : null,

      parcels: [{
        dimensions: formValues.parcel.dimensions,
        insuranceValue: formValues.parcel.insuranceValue,
      }],
      operatorName: formValues.parcel.operatorName,
      postingCode: formValues.parcel.dropoffPoint,
      deliveryType: formValues.parcel.deliveryType,
      additionalServices: this.orderAdditionalServices,
      senderFirstName: formValues.sender.name.split(' ')[0],
      senderLastName: formValues.sender.name.split(' ').splice(1).join(' '),
      senderEmail: formValues.sender.email,
      senderBuildingNumber: formValues.sender.buildingNumber,
      senderFlatNumber: formValues.sender.flatNumber,
      senderStreet: formValues.sender.street,
      senderCity: formValues.sender.city,
      senderPhoneNumber: formValues.sender.phoneNumber,
      senderPostCode: formValues.sender.postCode,

      receiverFirstName: undefined,
      receiverLastName: undefined,
      receiverCompanyName: formValues.seller.name,
      receiverEmail: formValues.seller.email,
      receiverBuildingNumber: formValues.seller.buildingNumber,
      receiverFlatNumber: formValues.seller.flatNumber,
      receiverStreet: formValues.seller.street,
      receiverCity: formValues.seller.city,
      receiverPhoneNumber: formValues.seller.phoneNumber,
      receiverPostCode: formValues.seller.postCode,
      destinationCode: formValues.seller.pointCode,
      trackingReturnUrl: formValues.trackingReturnUrl
    }
  }

  public getDefaultLocationValidatorsForField(field: FormFieldName, isSender: boolean = false): ValidatorFn[] {
    if (field === FormFieldName.NAME && isSender) {
      return [...(this.defaultLocationFormValidators[field] || []), CustomValidators.nameWithLastname];
    }

    return this.defaultLocationFormValidators[field] || [];
  }

  private mapReturnItemFormToModel(formItems: ProductItemValues[]): ProductItem[] {
    return formItems
      .map(c => {
        return {
          itemName: c.description,
          itemCount: c.quantity,
          itemPrice: c.unitPrice
        }
      });
  }

  private createReturnedProductForm() {
    return new FormGroup({
      shopOrderNumber: new FormControl(null,
        [
          this.validatorBasedOnFormType,
          CustomValidators.bpmaxlength(64)
        ] as ValidatorFn[]
      ),
      productItems: this.formBuilder.array(
        [this.createProductItemForm(0)],
        [
          this.validatorBasedOnFormType
        ] as ValidatorFn[]
      ),
      returnReason: new FormControl(null, [
        this.validatorBasedOnFormType
      ] as ValidatorFn[]),
      returnReasonAdditionalInfo: new FormControl(null, [
        CustomValidators.bpmaxlength(255)
      ] as ValidatorFn[])
    });
  }

  private createProductItemForm(id: number): FormGroup {
    return this.formBuilder.group({
        description: new FormControl(null,
          [
            this.validatorBasedOnFormType,
            CustomValidators.bpmaxlength(99)
          ] as ValidatorFn[]
        ),
        quantity: new FormControl(null,
          [
            this.validatorBasedOnFormType,
            Validators.min(1),
            CustomValidators.number,
            CustomValidators.bpmaxlength(3)
          ] as ValidatorFn[]
        ),
        unitPrice: new FormControl(null,
          [
            this.validatorBasedOnFormType,
            Validators.min(0.01),
            CustomValidators.number,
            CustomValidators.bpmaxlength(10)
          ] as ValidatorFn[]
        ),
        itemId: new FormControl(id,
          [] as ValidatorFn[]
        )
      })
  }

  private createReturnParcelForm() {
    return new FormGroup({
      operatorName: new FormControl(null, [
        Validators.required,
      ] as ValidatorFn[]),
      dimensions: new FormGroup({
        length: new FormControl(null, [
          Validators.required,
          Validators.min(1),
          CustomValidators.number,
          CustomValidators.bpmaxlength(7)
        ] as ValidatorFn[]),
        width: new FormControl(null, [
          Validators.required,
          Validators.min(1),
          CustomValidators.number,
          CustomValidators.bpmaxlength(7)
        ] as ValidatorFn[]),
        height: new FormControl(null, [
          Validators.required,
          Validators.min(1),
          CustomValidators.number,
          CustomValidators.bpmaxlength(7)
        ] as ValidatorFn[]),
        weight: new FormControl(null, [
          Validators.required,
          Validators.min(0.1),
          CustomValidators.number,
          CustomValidators.bpmaxlength(7)
        ] as ValidatorFn[]),
      }),
      insuranceValue: new FormControl(null, [
        CustomValidators.number,
        Validators.min(1),
        CustomValidators.bpmaxlength(7)
      ] as ValidatorFn[]),
      deliveryType: new FormControl(null),
      dropoffPoint: new FormControl(null, [
        (control) => {
          const operatorName = control?.parent?.get('operatorName')?.value;
          return operatorName && !this.excludedOperatorsForPostingCode.includes(operatorName)
            ? Validators.required(control)
            : null;
        }
      ] as ValidatorFn[])
    });
  }

  private createLocationForm(isSender: boolean, disableReceiverFields: boolean) {
    return new FormGroup({
      name: new FormControl({value: null, disabled: disableReceiverFields},
        this.getDefaultLocationValidatorsForField(FormFieldName.NAME, isSender)),
      street: new FormControl({value: null, disabled: disableReceiverFields},
        this.getDefaultLocationValidatorsForField(FormFieldName.STREET)),
      buildingNumber: new FormControl({value: null, disabled: disableReceiverFields},
        this.getDefaultLocationValidatorsForField(FormFieldName.BUILDING_NUMBER)),
      flatNumber: new FormControl({value: null, disabled: disableReceiverFields},
        this.getDefaultLocationValidatorsForField(FormFieldName.FLAT_NUMBER)),
      postCode: new FormControl({value: null, disabled: disableReceiverFields},
        this.getDefaultLocationValidatorsForField(FormFieldName.POST_CODE)),
      city: new FormControl({value: null, disabled: disableReceiverFields},
        this.getDefaultLocationValidatorsForField(FormFieldName.CITY)),
      phoneNumber: new FormControl({value: null, disabled: disableReceiverFields},
        this.getDefaultLocationValidatorsForField(FormFieldName.PHONE_NUMBER)),
      email: new FormControl({value: null, disabled: disableReceiverFields},
        this.getDefaultLocationValidatorsForField(FormFieldName.EMAIL)),
      pointCode: new FormControl({value: null, disabled: disableReceiverFields},
        this.getDefaultLocationValidatorsForField(FormFieldName.POINT_CODE))
    });
  }

  private getDefaultLocationFormValidators() {
    return {
      [FormFieldName.NAME]: [
        Validators.required,
        CustomValidators.bpmaxlength(50),
        CustomValidators.nameWithLastname
      ],
      [FormFieldName.STREET]: [
        Validators.required,
        CustomValidators.bpmaxlength(30)
      ],
      [FormFieldName.BUILDING_NUMBER]: [
        Validators.required,
        CustomValidators.bpmaxlength(5)
      ],
      [FormFieldName.FLAT_NUMBER]: [
        CustomValidators.bpmaxlength(5)
      ],
      [FormFieldName.POST_CODE]: [
        Validators.required,
        CustomValidators.postcode
      ],
      [FormFieldName.CITY]: [
        Validators.required,
        CustomValidators.bpmaxlength(50)
      ],
      [FormFieldName.PHONE_NUMBER]: [
        Validators.required,
        CustomValidators.specialMobilePhone
      ],
      [FormFieldName.EMAIL]: [
        Validators.required,
        CustomValidators.email
      ],
      [FormFieldName.POINT_CODE]: []
    } as { [key in FormFieldName]: ValidatorFn[] };
  }
}
