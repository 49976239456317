import {FormFieldName} from "./receiver-field-mappings.model";

export const defaultRequiredFields: FormFieldName[] = [
  FormFieldName.NAME,
  FormFieldName.STREET,
  FormFieldName.BUILDING_NUMBER,
  FormFieldName.POST_CODE,
  FormFieldName.CITY,
  FormFieldName.PHONE_NUMBER,
  FormFieldName.EMAIL
];

export const excludedOperatorsForPostingCode: string[] = ["INPOST"];
