export enum FormFieldName {
  NAME = 'name',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  POINT_CODE = 'pointCode',
  STREET = 'street',
  BUILDING_NUMBER = 'buildingNumber',
  FLAT_NUMBER = 'flatNumber',
  POST_CODE = 'postCode',
  CITY = 'city',
}

export interface FieldMapping {
  requiredFields: FormFieldName[];
  visibleFields: FormFieldName[];
}

export interface OperatorMappings {
  [deliveryType: string]: FieldMapping;
}

export interface ReceiverFieldMappings {
  [operator: string]: OperatorMappings;
}

export const receiverFieldMappings: ReceiverFieldMappings = {
  'INPOST': {
    'P2P': {
      requiredFields: [FormFieldName.NAME, FormFieldName.EMAIL, FormFieldName.PHONE_NUMBER, FormFieldName.POINT_CODE],
      visibleFields: [FormFieldName.NAME, FormFieldName.EMAIL, FormFieldName.PHONE_NUMBER, FormFieldName.POINT_CODE]
    },
    'P2D': {
      requiredFields: [FormFieldName.NAME, FormFieldName.EMAIL, FormFieldName.PHONE_NUMBER, FormFieldName.STREET, FormFieldName.BUILDING_NUMBER, FormFieldName.POST_CODE, FormFieldName.CITY],
      visibleFields: [FormFieldName.NAME, FormFieldName.EMAIL, FormFieldName.PHONE_NUMBER, FormFieldName.STREET, FormFieldName.BUILDING_NUMBER, FormFieldName.FLAT_NUMBER, FormFieldName.POST_CODE, FormFieldName.CITY]
    }
  },
};
